import './Loading.css'

function Loading () {

    return (
        <div className="loading">
            <div className='loadingSymbol'></div>
        </div>
    )
}

export default Loading