export {fetchProgress};

async function fetchProgress(details) {
    const {body, userId, loginKey, scenarioId, getAll} = details;
    const url = "https://api.malstrom.art/murderMystery/progress.php";
    let request;

    if (body) {
        request = new Request(url, body);
    } else  if (getAll) {
        request = new Request(`${url}?userId=${userId}&loginKey=${loginKey}&scenarioId=${scenarioId}&getAll=${getAll}`);
        
    } else {
        request = new Request(`${url}?userId=${userId}&loginKey=${loginKey}&scenarioId=${scenarioId}`);
    }
    try {
        const response = await (  (await fetch(request)).json()  );
        return response;

    } catch (error) {
        console.log(error);
        return {
            payload: [],
            success: false,
            message: "internal server error"
        }
    }
}