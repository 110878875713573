import { useState } from "react";
import "./MakeAccusationTab.css"
import { calculateTime } from "../../../../functions/caseFunctions";

function MakeAccusationTab ({progress, handleSetProgress, scenario, tabs, setTabs, evidence, setEvidence, clearEvidence,chosenSuspect, setChosenSuspect}) {
    const [accusationResults, setAccusationResults] = useState(null);
    const {suspects, } = scenario;
    const {name, image} = suspects[chosenSuspect];


    const accusationCalled = accuseSuspect({evidence, chosenSuspect:suspects[chosenSuspect]});

    if (accusationCalled === "wrong" && !accusationResults) {
        const {endTime} = progress;
        handleSetProgress({endTime: endTime - 60 * 1000});
        setAccusationResults("wrong");
    }
    if (accusationCalled === "correct") {

        const {startTime} = progress;
        const submittedTime = new Date().getTime() - startTime;
        const ending = {
            endingId: 1,
            time: calculateTime({submittedTime})
        }

        handleSetProgress({ending});
        return
    }

    const handlePickClue = (index) => {
        const evidenceCopy = Object.assign({}, evidence);
        if (accusationResults) {

            for (const key in evidenceCopy) {
                if (key !== index) {
                    evidenceCopy[key] = {}
                } else {
                }
            }

            evidenceCopy[index].active = true;
            setEvidence(evidenceCopy);
            setAccusationResults(null);
        } else {
            setEvidence(evidenceCopy, evidenceCopy[index] = {...evidenceCopy[index] ,active: true});

        }
        setTabs([...tabs, {type: "evidence", data:null}]);
    }

    const handleChangeSuspect = (direction) => {
        clearEvidence();
        setAccusationResults(null)
        if (direction === "next") {
            chosenSuspect === suspects.length-1 ? setChosenSuspect(0) : setChosenSuspect(chosenSuspect+1);
        } 
        if (direction === "previous") {
            chosenSuspect === 0 ? setChosenSuspect(suspects.length-1) : setChosenSuspect(chosenSuspect-1);
        } 
    }

    return (
        <div className="makeAccusationTab tabContent">
            <div className="topContainer">
                <div className="chooseSuspect">
                    <button 
                        className="previous"
                        onClick={()=>{handleChangeSuspect("previous")}}>
                    </button>
                    <img className="image" src={`./media/characters&items/${image}`} alt={`${name}`} />
                    <button 
                        className="next"
                        onClick={()=>{handleChangeSuspect("next")}}>
                    </button>
                </div>
                <h1 className="name">{name}</h1>
            </div>

            <div className="cluePicker">
                    
                <div className="accusationFeedback">
                    { accusationResults === "wrong" ? <div>wrong</div> : null}
                    { accusationResults === "correct" ? <div>correct</div> : null}
                    { !accusationResults ? <div>choose evidence</div> : null}
                </div>

                <div className={accusationResults === "wrong" ? "cluePickedOne interactionButton cluePicked wrong" : "cluePickedOne interactionButton cluePicked"}
                    onClick={()=>{
                        handlePickClue(1);
                    }}
                >
                    <div className={accusationResults === "wrong" ? "frame wrong": "frame"}></div>
                    <div className="bg"></div>
                    <div className={evidence[1].image !== undefined ? "clue chosen" : "clue"} style={{backgroundImage: evidence[1].image !== undefined ? `url(./media/characters&items/${evidence[1].image})` : null}}></div>
                </div>
                <div className={accusationResults === "wrong" ? "cluePickedTwo interactionButton cluePicked wrong" : "cluePickedTwo interactionButton cluePicked"}
                    onClick={()=>{
                        handlePickClue(2);
                    }}
                >
                    <div className="frame"></div>
                    <div className="bg"></div>
                    <div className={evidence[2].image !== undefined ? "clue chosen" : "clue"} style={{backgroundImage: evidence[2].image !== undefined ? `url(./media/characters&items/${evidence[2].image})` : null}}></div>

                </div>
                <div className={accusationResults === "wrong" ? "cluePickedThree interactionButton cluePicked wrong" : "cluePickedThree interactionButton cluePicked"}
                    onClick={()=>{
                        handlePickClue(3);
                    }}
                >
                    <div className={accusationResults === "wrong" ? "frame wrong": "frame"}></div>
                    <div className="bg"></div>
                    <div className={evidence[3].image === undefined ? "clue" : "clue chosen"} style={{backgroundImage: evidence[3].image !== undefined ? `url(./media/characters&items/${evidence[3].image})` : null}}></div>

                </div>
            </div>
        </div>
    )
}

function accuseSuspect({evidence, chosenSuspect}) {
    let abort = false;
    for (const key in evidence) {
        const {id, active} = evidence[key];
        if (active) {
            abort = true;
        }

        if (id === undefined || id === null) {
            abort = true;
        }
    }
    if (abort) { return false }

    if (!chosenSuspect.guilty) {
        return "wrong"
    }

    const evidenceNeeded = chosenSuspect.evidence

    for (const key in evidence) {
        const {id} = evidence[key];
        if (!evidenceNeeded.includes(id)) {
            return "wrong"
        }
    }

    return "correct"
}

export default MakeAccusationTab