import "./Stats.css";

function Stats ({ progress, scenario}) {
    const {ending, clues, locations} = progress;
    const {time, endingid} = ending;
    const{hours, minutes, seconds} = time;


    return (
        <ul className="stats">
            <li className="">
                <div className="time icon"></div>
                <div className="content">
                    {hours < 10 ? "0"+hours: hours}:{minutes < 10 ?  "0"+minutes : minutes}:{seconds < 10 ? "0"+seconds: seconds}
                </div>
            </li>
            <li className="">
                <div className="clues icon"></div>
                <div className="content">
                    {clues.length} / {scenario.clues.length}
                </div>
            </li>
            <li className="">
                <div className="location icon"></div>
                <div className="content">
                    {locations.length} / {scenario.locations.length} 
                </div>
            </li>
        </ul>
    )
    
}


export default Stats