import { useState } from "react";
import "./AddClueTab.css"

function ClueTab ({data, progress, handleSetProgress, scenario}) {
    const [clueSolved, setClueSolved] = useState(null);
    const [input, setInput] = useState("");
    const {question, answer, image, name, id, unlocksLocation} = data;
    const {clues} = progress;

    const handleInput = (e) => {
        if (e === "clear input") {
            document.getElementById("clueAnswerInput").value = "";
            return
        }

        if (clueSolved === false) {
            setClueSolved(null)
        }
        setInput(e.target.value.toUpperCase());
    }

    const handleSubmit = (e) => {
        if (input !== answer) {  setClueSolved(false); return }
        let firstClueUnlocked = clues.length === 0 ? true : false;


        for (const locationId of unlocksLocation) {
            progress.locations.push(locationId);
        }
        progress.clues.push(id);

        if (firstClueUnlocked) {
            const {hours, minutes} = scenario.time;
            const startTime = new Date().getTime();
            const endTime = startTime + ( hours*60*60*1000 +  60*1000*minutes  );
            progress.startTime = startTime;
            progress.endTime = endTime
            handleSetProgress({clues: progress.clues, locations: progress.locations, startTime: progress.startTime, endTime: progress.endTime});
        } else {
            handleSetProgress({clues: progress.clues, locations: progress.locations});
        }

        setClueSolved(true);
    }

    return (
        <div id="addClueTab" className="tabContent">
            <h3>{clueSolved ? "Clue unlocked" : question}</h3>

            <div className={clueSolved ? "inputContainer clueUnlocked": "inputContainer"}>
                <div className="inputWrapper">
                    <input type="text" value={input} placeholder="answer" 
                        onChange={handleInput} 
                        onKeyUp={(e)=>{e.key === "Enter" && handleSubmit();}}
                        className={clueSolved === false ? "wrong" : null}
                        />
                    <button className={clueSolved === false ? "wrong" : null}
                        onClick={handleSubmit}
                    ></button>
                </div>
                
                <div className={clueSolved ? "clueContainer clueUnlocked" : "clueContainer"}>
                    <div className="clueIconWrapper">
                        <img src={`./media/characters&items/${image}`} alt={`image of ${image}`} className="clueIcon"/>
                        <div className={clueSolved ? "cover hide" : "cover"}></div>
                        <div className={clueSolved ? "lock unlocked" : "lock locked"}></div>
                    </div>
                    <div className="clueName handWritten">{name}</div>
                    {clueSolved ? <div className="areas">*New clue unlocked</div> : <div></div>}
                    {unlocksLocation.length > 0 && clueSolved ? <div className="areas">*New area unlocked</div> : <div></div>}
                </div>
            </div>

            
        </div>
    )
}

export default ClueTab