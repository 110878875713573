import { useState, useEffect } from 'react';
export { useCountdown, useGetLeaderboardListing };

const useCountdown = ({endTime, scenario}) => {
    const [countDown, setCountDown] = useState( null );
    useEffect(() => {
    const interval = setInterval(() => {
        if (endTime === null) {
          setCountDown(null);
        } else {
          setCountDown(endTime - new Date().getTime());
        }
    }, 1000);

    return () => clearInterval(interval);
    }, [endTime]);


    return getReturnValues(countDown, scenario);
};

const getReturnValues = (countDown, scenario) => {

  if (countDown === null) {
    const {hours, minutes} = scenario.time;
    return {hours, minutes, seconds:0}
  }
  // calculate time left
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return {hours, minutes, seconds};
};



const useGetLeaderboardListing = ({scenarioId, userId}) => {
  const [leaderboardResults, setLeaderboardResults] = useState([])

  useEffect(()=>{
    const request = new Request(`https://api.malstrom.art/murderMystery/progress.php?scenarioId=${scenarioId}&userId=${userId}&getAll=true`)
    fetch(request)
      .then(r => r.json())
      .then(response => {
        const {payload, success} = response;
        if (success) {
          const allUsersProgress = payload.allUsersProgress.filter(x => x.progress.ending !== null);
          if (allUsersProgress.length > 1) {
            allUsersProgress.sort((a,b) => {
              const resultsA = a.progress.ending.time.totalTime;
              const resultsB = b.progress.ending.time.totalTime;
  
              if (resultsA < resultsB) {
                return -1
              }
              if (resultsA > resultsB) {
                return 1
              }
              return 0
  
  
            })
            
          }

          setLeaderboardResults(allUsersProgress);
        }
      })

  }, []);

  return {leaderboardResults};

}
