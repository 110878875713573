import "./Conclusion.css"
import AudioButton from "../../general/AudioButton/AudioButton";
function Conclusion ({conclusion, soundFileName,handleSoundfile, soundfile}) {


    return (
        <div className="conclusion">
            <div className="topContainer">
                <AudioButton fileName={soundFileName} handleSoundfile={handleSoundfile} soundfile={soundfile}></AudioButton>
            </div>
            <p>
            {conclusion}
            </p>
        </div>
    )
    
}


export default Conclusion