import "./Li.css"

function Li ({data, LiEvent, interactionButtonEvent}) {

    const {image, name} = data;

    const callLiEvent = (arg) => {
        LiEvent(arg);
    }
    const callInteractionButtonEvent = (e) => {
        e.stopPropagation();
        interactionButtonEvent === undefined ?
        callLiEvent(e)
            :
        interactionButtonEvent();
    }

    return (
        <li className="commonLi" onClick={callLiEvent}>
            <div className="wrapper">

                <img className="image" src={`./media/characters&items/${image}`} alt={`image of ${name}`} />
                <div className="name">{name}</div>
            </div>
            <button 
                className="interactionButton" 
                onClick={callInteractionButtonEvent}>
            </button>
        </li>
    )
}

export default Li