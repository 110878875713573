import React, {useState } from "react";
import Loading from "../Loading/Loading";


import './LoginRegister.css'
import {attemptLogin, attemptRegister } from "../../functions/attemptLogin";

function LoginRegister({setToken}) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [mode, setMode] = useState("login");


    const handleSubmit = async () => {
        setLoading(true);
        if (mode === "login") {
            const {message, success, payload} = await attemptLogin({username, password});
            setLoading(false);
            if (success) {
                const {loginKey} = payload;
                localStorage.setItem("key", loginKey);
                setToken(loginKey);
            } else {
                setMessage(message);

            }
        }


        if (mode === "register") {
            const {message, success, payload} = await attemptRegister({username, password});
            setLoading(false);
            if (success) {
                setMessage(message);
            } else {
                setMessage(message);

            }
        }

    }
    
    return (
        <div id="loginRegister">
            <form>
                <div className="topSection">
                    <div className="messageContainer">{message}</div>
                </div>
                <div className="bottomSection">

                {/* <Loading></Loading> */}

                {loading === true ? 
                    (<Loading/>) 
                    : 
                    (
                    <>
                        <div className="inputs">
                            <input type="text" placeholder="username" 
                                onChange={(e)=>{setUsername(e.target.value)}}
                                value={username}
                            />
                            <input type="text" placeholder="password" 
                                onChange={(e)=>{setPassword(e.target.value)}}
                                value={password}
                            />
                        </div>
                        <button className="interactionButton" onClick={(e)=>{e.preventDefault(); handleSubmit()}}>{mode}</button>

                        {mode === "login" ? 
                        (<button className="switch" onClick={(e)=>{e.preventDefault(); setMode("register")}}>Don't have an account? <span>Sign up</span></button>) 
                        : 
                        (<button className="switch" onClick={(e)=>{e.preventDefault(); setMode("login")}}>Already have an account? <span>Login</span></button>)}
                    </>
                    )}


                </div>
            </form>
        </div>
    )
}

export default LoginRegister