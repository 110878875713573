import "./View.css"

import Map from "./Map/Map";
import Clues from "./Clues/Clues";
import Suspects from "./Suspects/Suspects";
import Casefile from "./CaseFile/Casefile";

function View ({view, progress, setProgress, scenario, setScenario,setTabs, tabs, handleSoundfile, soundfile}) {
    let Content = Clues;
    if (view === "Map") { Content = Map; }
    if (view === "Clues") { Content = Clues; }
    if (view === "Suspects") { Content = Suspects; }
    if (view === "Casefile") { Content = Casefile; }

    // stop unecessary rendering since the user shouldn't use or see the content when in a tab 
    if (tabs.length > 0) { return null }

    return (
        <div id={`${view}View`} className="view">
            <div className="title colorMain">{view}</div>
            <div className="viewContent">
                <Content
                    progress={progress}
                    setProgress={setProgress}
                    scenario={scenario}
                    setScenario={setScenario}
                    setTabs={setTabs}
                    tabs={tabs}
                    handleSoundfile={handleSoundfile}
                    soundfile={soundfile}
                />
            </div>
        </div>
    )
}

export default View