import "./OpenTab.css";
import ClueTab from "./ClueTab/ClueTab";
import AddClueTab from "./AddClueTab/AddClueTab";
import SuspectsTab from "./SuspectsTab/SuspectsTab";
import MakeAccusationTab from "./MakeAccusationTab/MakeAccusationTab";
import ChooseEvidenceTab from "./ChooseEvidenceTab/ChooseEvidenceTab";

function OpenTab ({scenario, tabData, tabs, setTabs, progress, setProgress, evidence, setEvidence, chosenSuspect, setChosenSuspect, handleSetProgress, clearEvidence, soundfile,handleSoundfile}) {
    const {type, data} = tabData;
    let TabContent = ClueTab;
    if (type === "clue") { TabContent = ClueTab; }
    if (type === "addClue") { TabContent = AddClueTab; }
    if (type === "suspect") { TabContent = SuspectsTab; }
    if (type === "accusation") { TabContent = MakeAccusationTab; }
    if (type === "evidence") { TabContent = ChooseEvidenceTab; }

    if (tabs.indexOf(tabData) !== tabs.length-1) { return }

    const removeCurrentTab = (e) => {
        e.stopPropagation();
        setTabs(tabs.filter(tab => tabs.indexOf(tab) !== tabs.indexOf(tabData)))
    }

    return (
        <div className="tab">
            <TabContent
                data={data}
                progress={progress}
                setProgress={setProgress}
                scenario={scenario}
                tabs={tabs}
                setTabs={setTabs}
                evidence={evidence}
                setEvidence={setEvidence}
                clearEvidence={clearEvidence}
                removeCurrentTab={removeCurrentTab}
                chosenSuspect={chosenSuspect}
                setChosenSuspect={setChosenSuspect}
                handleSetProgress={handleSetProgress}
                handleSoundfile={handleSoundfile}
                soundfile={soundfile}
            />
        </div>
    )
}

export default OpenTab