import { useCountdown } from "../../../functions/customHooks";
import "./Counter.css"

function Counter ({progress, handleSetProgress, scenario, tabs, setTabs, clearEvidence, handleSoundfile, soundfile}) {
    const {endTime, ending} = progress;
    const {hours, minutes, seconds} = useCountdown({endTime, scenario});
    const {currentAudio, currentSoundfile} = soundfile

    if (endTime && !ending &&  hours + minutes + seconds <= 0) {
        const {hours, minutes,} = scenario.time
        const ending = {
            endingId: 2,
            time: {
                hours,
                minutes,
                seconds:0,
                totalTime: hours*60*60 + minutes*60
            }
        }

        handleSetProgress({ending});
    }
    return (
        <div id="counter">
            <div className="timer">{hours < 10 ? "0"+hours: hours}:{minutes < 10 ?  "0"+minutes : minutes}:{seconds < 10 ? "0"+seconds: seconds}</div>


            {!currentAudio ? null : (
            <div className={currentAudio ? "soundControl active":"soundControl"}
                onClick={()=>{handleSoundfile(currentSoundfile)}}>

                <div className={currentAudio.paused ? "controlWrapper paused" : "controlWrapper playing"}>
                    <div className="grammophone"></div>
                    <div className="button"></div>
                </div>
            </div>
            )}

            {tabs.length > 0  ? (            
                <button 
                    className="return" 
                    onClick={(e)=>{
                        e.stopPropagation();
                        if (tabs[tabs.length -1].type === "accusation") {
                            clearEvidence();
                        }
                        setTabs(tabs.filter(tab => tabs.indexOf(tab) !== tabs.length-1))
                    }}>
                    <div className="arrow"></div> return
                </button>
            ) : <div></div>}
        </div>
    )
}

export default Counter