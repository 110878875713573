import {useState } from 'react';
import {attemptLogin} from './functions/attemptLogin';
import './App.css';
import LoginRegister from './components/LoginRegister/LoginRegister';
import MainMenu from './components/MainMenu/MainMenu';
import Loading from './components/Loading/Loading';
import Scenario from './components/Scenario/Scenario';
import Leaderboard from './components/Leaderboard/Leaderboard';


function App() {
  const [token, setToken] = useState(localStorage.getItem("key"));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(null);
  const [scenario, setScenario] = useState(null);
  const [leaderboard, setLeaderBoard] = useState(null);


  // first time visiting the site aka no user saved in state
  if (!user && token && !loading) {
    (async ()=>{
      setLoading(true);
      const {success, message, payload} = await attemptLogin(
        {
          loginKey: token,
          username: null,
          password: null
        }
      );

      if (success) {
        const {userId, username} = payload;
        setUser({userId, username});
      } else {
        localStorage.removeItem("key");
        setToken(null);
      }

      setLoading(null);

    })();
  }

  if (loading) {
    return(
      <main id="main">
        <Loading/>
      </main>
    )
  }



  const openLeaderBoard = () => {
    setLeaderBoard(true);
    setScenario(false);
  }

  const playScenario = () => {
    // hämta caseFile från DB / localStorage
    setLeaderBoard(false);
    setScenario(caseFile);
  }

  return (
    <main id="main">
      {user === null || user === undefined ? (<LoginRegister setToken={setToken}/>): null}

      {user && scenario ? <Scenario  scenario={scenario} user={user} setScenario={setScenario} /> : null}
      {user && leaderboard ? <Leaderboard setLeaderBoard={setLeaderBoard} user={user} scenarioId={0}/> : null}
      {user && !scenario && !leaderboard ? <MainMenu 
          user={user}
          playScenario={playScenario}
          setUser={setUser}
          setScenario={setScenario}
          setToken={setToken}
          openLeaderBoard={openLeaderBoard}
        /> : null}
    </main>
  );
}

export default App;


const caseFile = {
  scenarioId: 0,
  name: "ORMET",
  description: `One day the fisherman Karl Berg unexpectedly reels in a mysterious book filled with cryptic symbols and numbers. Disturbed by a sense of unease, he hurls it back into the murky depths of the canal, yet as night falls, it inexplicably reappears on his bedside table. Seeking help, he confides in the Pastor Nobelius, who, along with professor Jakobsson, tries to read its contents. Meanwhile, the pastor’s wife Agneta, notices changes in her husband as the book's presence affects him. Despite attempts to get rid of it, the book persists, leading to tensions between the pastor and the fisherman.
  
  The local doctor Stoltz, who conducts regular home visits, notices a changed behavior since the book entered their lives. This led her to also come into contact with the book and discover an interest in it. 

  A few days later, on a chilly November night, the Pastor was found murdered by Ormet in Slottsparken in Malmö. His body was hanged with a rope and placed in a bizarre ritualistic manner. The body was covered in the smell of dead fish. Additionally some form of strong drug or medicine had been used to render the pastor unable to resist.
  
  Now the book is missing and it’s your job as detective to solve this mysterious murder...`,
  soundfileName: "casefile",
  time: {
    hours: 1,
    minutes: 45,
  },


  clues: [
    {
      id: 0,
      name: "Sea weed & fish scales",
      description: "The clothing of the pastor are all stuffed with sea weed and fish scales. Another strange detail about this case...",
      image: "seaweed.png",
      unlocksLocation: [],
      locationId: 0,
      coordinates: {
        lat: 55.603848,
        lng: 12.992383
      },
      question: "what is the name of the serpents cave?",
      answer: "ORMET",
      soundfileName: "seaweed",
    },
    {
      id: 1,
      name: "Empty medicine vial",
      description: `In the victims left pocket an empty vial for medicine is found. Perhaps this is the source of the pastors intoxicated state.`,
      image: "vial.png",
      unlocksLocation: [],
      locationId: 0,
      coordinates: {
        lat: 55.603813,
        lng: 12.992416
      },
      question: "What drips from the serpents mouth?",
      answer: "WATER",
      soundfileName: "vial",
    },
    {
      id: 2,
      name: "witness: Casino Jensen",
      description: `You turn around to see a fine dressed man from the Casino
      
      "Hello detective, 
      I am terribly sorry to disturb you, but I have something I wish to discuss. "
      
     " I work at the Casino here in the park. I'm not particular religious but I do owe much to the pastor, he was good to my family during the worst of the spanish flu."

      "At the night of the murder I worked the late shift and I saw the pastor go home with his Wife the Cantor."
   
      "however... I believe someone followed them. I never saw who."
      
      Perhaps a visit to the church could give more clues on the Cantor.`,
      image: "casino.jpg",
      unlocksLocation: [1],
      locationId: 0,
      coordinates: {
        lat: 55.603925,
        lng: 12.992410
      },
      question: "Fill in the blank: _____stigen",
      answer: "KOMMENDANT",
      soundfileName: "casino",
    },
    // cemetary 
    {
      id: 4,
      name: "witness: Choir member Julia",
      description: `"Oh hello Detective."
      
      "My name is Julia. I see, you wish to hear more about the Cantor?"
      
      "Most find her rather intimitadating but once you get to know her she really is a gentle soul, always wishing for a better life for others. She always worked tiredlessly for the church, eager to prove herself ever as capable as the pastor." 
      
      "As for the last days though she has been acting strange. Her mood always switching with the weather. I belive she and the pastor as of late argued often and then this horrifying business with his death.”

      “No wonder she hasn’t been acting right.”`,
      image: "choir.jpg",
      unlocksLocation: [],
      locationId: 1,
      coordinates: {
        lat: 55.602309,
        lng: 12.99398
      },
      question: "When was the gate raised?",
      answer: "1911",
      soundfileName: "choir",
    },
    {
      id: 6,
      name: "ripped out page",
      description: `A page out of a book, the page is filled with strange symbols and unreadable, endless notes. Perhaps it belonged to the pastor's and professor's work. 
      
      Is it somehow related to the book from the ocean?
      
      Perhaps a visit to the library can give more clues on the professor`,
      image: "page.png",
      unlocksLocation: [2],
      locationId: 1,
      coordinates: {
        lat: 55.602436,
        lng: 12.993925
      },
      question: "Fill in the blanks: bird house or bird ____",
      answer: "CAGE",
      soundfileName: "page",
    },
    {
      id: 7,
      name: "Salvia",
      description: `A common medicinal herb, most often used to reduce headaches. 
      
      The curch often help give out remedies to the ones in need, a practice often done by the cantor`,
      image: "salvia.png",
      unlocksLocation: [],
      locationId: 1,
      coordinates: {
        lat: 55.602735,
        lng: 12.993976
      },
      question: "Fill in the blanks: The tree is a ____-cat",
      answer: "WILD",
      soundfileName: "salvia",
    },
    // LIBRARY
    {
      id: 8,
      name: "Witness: Assistant Sara",
      description: `"Greetings, I am the assistant of professor Jakobsson."

      "You wish to see the professor? That is impossible I am afraid."
      
      "The professor is a rather.. dedicated man, he always spends his days covered in books and research papers. Sometimes I think he doesn't ever sleep and his ambitions can at times overcome him, His latest obession has been this book, he believes it will prove all his theories true."

      “It seems everyone is obsessed with this book.. Priests, professors even fishermen..."`,
      image: "assistant.jpg",
      unlocksLocation: [],
      locationId: 2,
      coordinates: {
        lat: 55.601074,
        lng: 12.994339
      },
      question: "When were the seeds of the apple planted",
      answer: "2006",
      soundfileName: "assistant",
    },
    {
      id: 9,
      name: "Strange artifact",
      description: "A strange object covered in carved in runes. It seem to be an object of research, maybe it's related to the book from the ocean and the page found by the curch's cemetary",
      image: "artifact.png",
      unlocksLocation: [],
      locationId: 2,
      coordinates: { // flytta
        lat: 55.601048,
        lng: 12.994536
      },
      question: "What unluck is the tree marked with", 
      answer: "13",
      soundfileName: "strangeArtifact",
    },
    {
      id: 10,
      name: "Professors journal",
      description: `A entry record of discoveries of the prefessors research on the mysteries book
      The latest entries were:
      
      "I've come to decipher the author of the book, it is Abdul Alhazred! the same author as the necronomicon."

      "In the book a central piece is a fulfilled promise and a price payed. However I can't grasp its meaning"

      "Finally! Oh it all seem clearer now... the desire of one is payed by the another * unreadable *"

      "That damned pastor he means to take away all now that I finally have my path to recognition... and that blasted Cantor always spying, always asking questions... I believe she is on the pastors side"

      "I can't think straight I must devote myself further for I am so close. I have placed a large order of soothing Syrup from Dr Stoltz"
      
      Perhaps it is time to investigate Doctor Stoltz`,
      image: "journal.png",
      unlocksLocation: [3],
      locationId: 2,
      coordinates: {
        lat: 55.600737,
        lng: 12.994713
      },
      question: "On the library's tower sits an animal",
      answer: "ROOSTER",
      soundfileName: "journal",
    },
    // Doctor's garden
    {
      id: 11,
      name: "Recipe for medicine",
      description: `Seemingly a list of ingredients needed to make medicine against the spanish flu
      
      1. Birch tree sap
      2. Dandelion extract
      3. Ox's Adrenal glands
      4. ... 
      
      "NOTE: I will attempt treating the Pastors condition at our next encounter"
      "NOTE: DO NOT COMBINE WITH SALVIA, can cause serious damage to the nervous system and loss of bodily control"`,
      image: "recipe.png",
      unlocksLocation: [],
      locationId: 3,
      coordinates: {
        lat: 55.602855,
        lng: 12.986249
      },
      question: "What game is played in the garden",
      answer: "CHESS",
      soundfileName: "recipe",
    },
    {
      id: 12,
      name: "The Book from the Sea",
      description: `The missing book! Somehow the book have been placed in the hands of doctor Stoltz, did she steal it? For what purpose could she have stolen it?
      
      As you touch the book you get a feeling of unease and distress.
      
      This chilling feeling.. This is why the pastor and the fisherman decided to try and get rid of it. Clearly the right decision.
      
      Sensing its dangerous presence you decide not to look into the book.
      
      Perhaps this is a good time to visist the fisherman's hut.`,
      image: "book.png",
      unlocksLocation: [4],
      locationId: 3,
      coordinates: {
        lat: 55.602965,
        lng: 12.986361
      },
      question: "What 3 similar entraces are there into the doctors house from the garden?",
      answer: "WINDOW",
      soundfileName: "theBook",
    },
    {
      id: 13,
      name: "Witness: Nurse Maria",
      description: `"Welcome to Saint hospital gardens how may I help you?"

      "You wish to know more about Dr Stoltz? and the night of the murder?"
      
      "Well the doctor was strangely enough missing that evening, we had a distress call and she showed up much too late. She usually is such a caring person. She always does personal house visits to those that can't make it to the hospital, such as the late pastor. As of late she has been acting rather peculiar."
      
      "This is not too strange however since she has been working around the clock both treating the locals and working on a new medicine... She lost her whole family to the spanish flu you see and is very dedicated to finding a cure.`,
      image: "nurse.jpg",
      unlocksLocation: [],
      locationId: 3,
      coordinates: {
        lat: 55.603711,
        lng: 12.987831
      },
      question: "Inside one out of three hatches sits 2 white flowers and a fruit, which fruit?",
      answer: "STRAWBERRY",
      soundfileName: "nurse",
    },
    // FISHING HUTS
    {
      id: 14,
      name: "Dried fish scales",
      description: "A disgusting and foul smell surrounds the area, the origin seem to be the drying fish scales. One can only wonder why the fisherman keeps them around",
      image: "driedFish.png",
      unlocksLocation: [],
      locationId: 4,
      coordinates: {
        lat: 55.60515,
        lng: 12.984066
      },
      question: "What hangs behind the fishing huts?",
      answer: "FISH SCALES",
      soundfileName: "dryingFish",
    },
    {
      id: 15,
      name: "Half written letter",
      description: `The fishermans hut is empty however a letter can be found placed on the desk.
      
      "Dear son
      
      "It is with a heavy weight that I write this letter. As of late my mind has been slipping and I can't think straight anymore..."
      
      "I know I sound mad but I write this with the conviction of a loving father to his son. Ever since I found that cursed book from the sea my senses have been whispering. Trying to concinve me that to save you I must do something horrendous."
      
      "I told that fool priest Nobelius to get rid of it but it is too late. The book have trapped many now and soon someone is bound to act on their desires."
  
      
      "A LAST WARNING the book is dangerous, you pay a life for a false promsise. Do not trust those that meddle with it."
      
      "I heard of the professors arguement, he is ambitous but if killing was enough to satisfy his desire he would've already done so."
      
      "The Doctor too has become fascinated with the book, she always asks me when she does home visits. But her only aim is to save life's"
      
      "The Cantor however... she you should never meet the eyes of. I followed them last night, she is capable of the most henious of acts. I am incapable of acting and I wish you not to attempt stopping her..."`,
      image: "letter.png",
      unlocksLocation: [],
      locationId: 4,
      coordinates: {
        lat: 55.605368,
        lng: 12.984098
      },
      question: "The fisherman's hut is locked with 4 digits... rearrange: 7 4 1 9",
      answer: "1947",
      soundfileName: "letterToSon",
    },
    {
      id: 16,
      name: "Boat line",
      description: "This is the same type of rope that the pastor was hanged with..",
      image: "rope.png",
      unlocksLocation: [],
      locationId: 4,
      coordinates: {
        lat: 55.605580,
        lng: 12.983957
      },
      question: "What is the last number of the docks",
      answer: "12",
      soundfileName: "rope",
    },
    {
      id: 17,
      name: "Witness: Lars Berg",
      description: `The fishermans son 
      
      "Who are you?"
      
      "You've come to investigate father?"
      
      "Well you can leave! He is innocent.."
      
      "... *sighs* I can't exactly blame you for suspecting him. As of late I haven't recognised him, he mumbles to himself and has frequent nightmares. He even stopped taking his medicine. But i beg you to understand he is not an evil man."

      “If anyone is suspicious its the cantor! I saw her steal one of our boat ropes and she’s always sneaking about.”`,
      image: "son.jpg",
      unlocksLocation: [],
      locationId: 4,
      coordinates: {
        lat: 55.605565,
        lng: 12.983328
      },
      question: "Fill in the blanks: _____linjen",
      answer: "MUSEI",
      soundfileName: "son",
    },
  ],
  suspects: [
    {
      id: 0,
      guilty: false,
      image: "fisherman.jpg",
      soundfileName: "fisherman",

      name: "Fisherman - Karl Berg",
      age: "47",
      motive: "The victims body had a strong stench of fish",
      alibi: '"I was at home during the murder. Well, I fought with the Pastor at the dock but it was about the book I came across a few days ago. "',
      description: `Karl Berg works as a fisherman in Malmö's port. On the night of the murder, he is seen arguing with the Pastor down by the do. Their dispute revolves around the book he found at sea. His son, Lars Berg, reports that he behaves strangely, often muttering about the Pastor and the book in his sleep. He mumbles phrases like 'darkness' and 'the deep sea'.`,
    },
    {
      id: 1,
      name: "Cantor - Agneta Nobelius",
      guilty: true,
      evidence: [7,10,11,15,17],
      image: "cantor.jpg",
      soundfileName: "cantor",

      age: "50",
      motive: "Close relations to the victim",
      alibi: `"I was saying usual Vespers, which is a evening prayer. I had rehearsed a special prayer with our choir that we performed together. That I would  murder my husband is absurd."`,
      description: `Cantor Agneta Nobelius was born in 1870 and was married to  pastor Enok Nobelius. She grew up in a loving and affectionate home, with parents who had a happy marriage that she dreamed of emulating. In the beginning, Agneta and the Pastor had a loving marriage, but as time went by he began to show her less and less love. Although she was extremely patient, her curiosity could only be satisfied by revealing all the secrets behind every closed door she encountered.`,

    },
    {
      id: 2,
      name: "Professor - Hans Jakobsson",
      guilty: false,
      age: "58",
      motive: "The victim and the professor Worked closely togheter the last days",

      alibi: `"I was researching in my studies the night of the murder. The pastor and I were working together on the translation of the symbols and we both wanted the same thing, to understand the content of the book. My place was at the desk and not at the scene of the murder."`,
      description: `Hans Jakobsson moved to Malmö in 1905 after finishing his studies. The Pastor contacted professor Hans Jakobsson, expert in the supernatural, asking for help to decipher the strange book. Supposedly the professors and the pastor worked closely together trying to find an answer to the book’s mysteries. Hans career had taken a turn for the worse as his research had been deemed as pseudoscience. With the book missing the professor is sat in his studies refusing to answer. `,
      image: "professor.jpg",
      soundfileName: "professor",
    },
    {
      id: 3,
      name: "Doctor - Rut Stoltz",
      guilty: false,
      soundfileName: "doctor",
      image: "doctor.jpg",


      age: "47",
      motive: "The victim was discovered to have been drugged",
      alibi: `"I was in the hospital the night of the murder, working. And it can be verified by my colleagues and medical records. That I would have been anywhere else during that time is absurd. As a doctor, I'm sworn to help and care, not to commit crime."`,
      description: `Doctor Rut Stoltz grew up in Malmö and lost her entire family to the Spanish flu. The grief and pain burning deep within her drove her to seek comfort and hope for others and help them heal. Today she works at the hospital and spends all her free time in the church. She loves working in the hospital and even conducts home visits to some of her patients. She often visited the Pastor and his wife before he was murdered.
      `,
    },
  ],
  locations: [
    {
      id: 0,
      name: "Crime scene",
      coordinates: {
        lat: 55.603789,
        lng: 12.992507
      },
      radius: 25,
  
    },
    {
      id: 1,
      name: "Cemetary",
      coordinates: {
        lat: 55.602542,
        lng: 12.994232
      },
      radius: 30,
  
    },
    {
      id: 2,
      name: "Library",
      coordinates: {
        lat: 55.601069,
        lng: 12.994353
      },
      radius: 40,
  
    },
    {
      id: 3,
      name: "Doctor's garden",
      coordinates: {
        lat: 55.603062,
        lng: 12.986602
      },
      radius: 100,
  
    },
    {
      id: 4,
      name: "Fishing huts",
      coordinates: {
        lat: 55.605402,
        lng: 12.983891
      },
      radius: 40,
  
    },
  ],
  endings:[
    {
      endingId:0,
      conclusion: "The murderer got away :(",
    },
    {
      endingId:1,
      conclusion: "Your calculations lead you to find the murderer. Good job! The Cantor have suffered from a loveless marriage and the book caused her to be free from this dismal marriage and took a part of her good morals also, and made her into a murderer. And by taking the life of the pastor she extinguished the district's light in the darkness",
      title: "The murder was caught",
      soundFileName: "ending1"
    },
    {
      endingId:2,
      conclusion: "As a detective you couldn't do your job well, unfortunately! While you spend the following week sifting through every clue and stack of notes in your office, doctor Stoltz is found hanging from the church chandelier. The townsfolk hold their breath in dread, while the culprit still walks in their midst",
      title: "Time is up!",
      soundFileName: "ending2"
    }
  ]
}