import {useGetLeaderboardListing} from "../../functions/customHooks"
import "./Leaderboard.css";

function Leaderboard ({ setLeaderBoard, user, scenarioId = 0}) {
    const {userId} = user;
    const {leaderboardResults} = useGetLeaderboardListing({scenarioId, userId});
    // Add for future: get Scenario via ID, if different scenarios are implemented, hard code clue length for now
    return (
        <div id="leaderboard">
            <div className="title">Leaderboard</div>
            <ul className="board">
                {leaderboardResults.map(result => {
                        const {user, progress} = result;
                        const {clues} = progress;
                        const {hours, minutes, seconds} = progress.ending.time;
                    return (
                        <li key={user}>
                            {leaderboardResults.indexOf(result)+1}. 
                            {user}

                            <div className="clues">{clues.length} / 16</div>

                            <div className="time">
                            {hours < 10 ? "0"+hours: hours}:{minutes < 10 ?  "0"+minutes : minutes}:{seconds < 10 ? "0"+seconds: seconds}
                            </div>
                        </li>)
                })}
            </ul>
            <button className="interactionButton" onClick={()=>{setLeaderBoard(false)}}>Back to main menu</button>
        </div>
    )
    
}


export default Leaderboard