export function filterLocationsFound({progress, scenario}) {
    return progress.locations.map(locationId => {
        return scenario.locations.find(x => x.id === locationId)
    });
    
}
export function filterCluesNotSolved({progress, scenario}) {
    const locationsFound = filterLocationsFound({progress, scenario})
    const cluesNotSolved = [];
    locationsFound.map(location => {
        const cluesInLocation = scenario.clues.filter(clue => clue.location === location.id)
        cluesInLocation.forEach(clue => {
            if (!progress.clues.includes(clue.id)) {
                cluesNotSolved.push(clue);
                
            }
        });
    });

    return cluesNotSolved
}

export function filterCluesFound({progress, scenario}) {
    const cluesFound = [];
    progress.clues.forEach(clueId => {
        const clue = scenario.clues.find(clue => clue.id === clueId);
        if (clue) {
            cluesFound.push(clue);
        }
    })
    return progress.clues.map(clueId => scenario.clues.find(clue => clue.id === clueId));    
}


export function getLocationClues({scenario, locationId}) {
    const {clues} = scenario;
    return clues.filter(x => x.locationId === locationId);
    
}

export function calculateTime({submittedTime}) {

    // seconds to minutes
    let seconds = Math.floor(submittedTime / 1000);
    // seconds to minutes
    let minutes = Math.floor(seconds / 60);
    // minutes to hours
    let hours = Math.floor(minutes / 60);

    for (let t = 0; t < hours; t++) {
        minutes = minutes - 60;
        seconds = seconds - 3600
    }
    for (let t = 0; t < minutes; t++) {
        seconds = seconds - 60;
    }

    return {
        hours,
        minutes,
        seconds,
        totalTime: submittedTime
    }
    
}
