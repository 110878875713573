import { useState } from "react";
import "./HowToPlay.css"
import { fetchProgress } from "../../../functions/DB";
import Loading from "../../Loading/Loading";
function HowToPlay ({scenario, setScenario, setProgress, userId, loginKey}) {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const {scenarioId, time} = scenario;
    const {hours, minutes} = time;
    const {name, instructions, image} = howToPlayPages[page];

    const previousPage = () => {
        if (page -1 < 0) {  return  }
        setPage(page -1)
    }
    const nextPage = () => {
        if (page +1 >= howToPlayPages.length) {  return  }
        setPage(page +1)
    }

    const startScenario = async () => {
        setLoading(true);
        const body = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                userId,
                loginKey,
                scenarioId,
            })
        }
        let {payload, message, success} = await fetchProgress({body});
        if (success) {
            const {newProgress} = payload;
            setProgress(newProgress);
            setLoading(false);
        }
    }

    const test = [
    ]

    return (
        <div id="howToPlay">

            <div className="pageContainer">
                {loading ? <Loading/> :      
                    <>
                        <h2 className="banner">How To Play</h2>


                        <div className="pageContentContainer">
                            <div className="iconContainer">
                                <div className="icon">
                                    <img src={`./media/icons/${image}.svg`} alt=""/>
                                </div>
                                {name}
                            </div>
                            <div className="instructionsContainer">
                                {instructions}
                            </div>
                        </div>

                    </>
                }
            

            </div>




            <div className="nav">
                <button 
                    onClick={()=>{setScenario(null)}}
                >Quit</button>
                <div className="pageSetter">
                    <button className="previous" onClick={previousPage} />
                        <div>{page+1} / {howToPlayPages.length}</div>
                    <button className="next" onClick={nextPage} />
                </div>
                <button 
                    className={page === howToPlayPages.length -1 ? "begin active" : "begin"}
                    onClick={startScenario}
                >Begin</button>
            </div>
        </div>
    )
}

export default HowToPlay



const howToPlayPages = [
    {
        name: "Case file",
        instructions: <div>
        Welcome detective.
        <br/>
        <br/>
        This is your case file, in it you can read about your case. The file includes important details so listen or read it carefully.
        <br/>
        Anywhere you see this symbol <span className="icon soundfile">OO</span> you can play a soundfile.
        <br/>
        <br/>
        Time is of the essence! 
        <br/>
        <br/>

        Your case has a limited time and if you can't accuse the cuplrit fast enough you lose.
        <br/>
        <br/>

        The timer will start once you have unlocked your first clue.
        
        
        </div>,
        image: "casefile",
    },
    {
        name: "Suspects",
        instructions: <div>
            For your case there will be a list of suspects.
            <br/>
            <br/>
            You can click on each suspect to inspect their information further.
            <br/>
            <br/>
            Here you can also accuse a suspect. However! To accuse you require the right evidence.
            <br/>
            <br/>
            Wrongfully accusing a suspect will result in a time penalty <span className="colorError">-1 minute</span>
        </div>,
        image: "suspects",
    },
    {
        name: "Map",
        instructions: <div>
            In your map all locations and clues will be shown.
            <br/>
            <br/>
            Use it to navigate around your local area.
            <br/>
            <br/>
            Each time a new area is unlocked clues will be scattered around that area.
            <br/>
            <br/>
            To interact with the clues and solve their question click on their <span className="icon clue">OO</span> icon!
        </div>,
        image: "map",
    },
    {
        name: "Clues",
        instructions: <div>
            Once you unlock clues they will be listed here.
            <br/>
            <br/>
            Inspect each clue closer and read their description to figure out who the culprit is.
            <br/>
            <br/>
            When you think you know who is guilty, the clues are used as evidence in your accusation.
            <br/>
            <br/>
            You need the right combination of evidence to accuse the right person.
            <br/>
            <br/>
            Click begin when you're ready.
        </div>,
        image: "clues",
    },
]