export {attemptLogin, attemptRegister};

async function attemptLogin({username, password, loginKey}) {
    const body = {
        headers: {'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify({
            username,
            password,
            loginKey
        })

    }
    const request = new Request('https://api.malstrom.art/murderMystery/login.php', body);

        try {
            const response = await (await fetch(request)).json();
            return response

        } catch (error) {
            return {message:"Internal Server Error", success: false, payload: null}
        }

}

async function attemptRegister({username, password}) {
    console.log("attempt to register");
    const body = {
        headers: {'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify({
            username,
            password,
        })

    }
    const request = new Request('https://api.malstrom.art/murderMystery/register.php', body);

        try {
            const response = await (await fetch(request)).json();
            return response

        } catch (error) {
            return {message:"Internal Server Error", success: false, payload: null}
        }

}
