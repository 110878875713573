import "./ClueTab.css"
import AudioButton from "../../general/AudioButton/AudioButton";
function ClueTab ({data, soundfile, handleSoundfile}) {
    const {image, name, description, soundfileName} = data;
    return (
        <div id="clueTab" className="tabContent">
            <div className="topContainer">
                <div className="topInfoWrapper">
                    <img className="image" src={`./media/characters&items/${image}`} alt={`${name}`} />
                    {!soundfileName ? null :(
                        <AudioButton
                        fileName={soundfileName}
                        handleSoundfile={handleSoundfile}
                        soundfile={soundfile}
                        />
                    )}
                </div>
                <h3 className="name">{name}</h3>

            </div>

            <div className="descriptionContainer">
                {description}
            </div>
        </div>
    )
}

export default ClueTab