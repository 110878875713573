import { useState } from "react"
import "./Scenario.css"
// Components
import Nav from "./Nav/Nav"
import View from "./views/View"
import OpenTab from "./OpenTab/OpenTab"
import Results from "./Results/Results"
import HowToPlay from "./HowToPlay/HowToPlay"
import Counter from "./Counter/Counter"
import { fetchProgress } from "../../functions/DB"


function Scenario ({scenario, setScenario, user}) {
    const [progress, setProgress] = useState(false);
    const [view, setView] = useState("Casefile");
    const [tabs, setTabs] = useState([]);
    const [evidence, setEvidence] = useState({ 1:{}, 2:{}, 3:{}});
    const [chosenSuspect, setChosenSuspect] = useState(0);
    const [soundfile, setSoundfile] = useState({currentSoundfile: null, currentAudio: null});


    const {userId} = user;
    const {scenarioId} = scenario;
    const loginKey = localStorage.getItem("key");



    // check for progress LocalStorage / DB
    if (!progress) {
        // setProgress(artificialProgress);

        const localProgress = isLocalProgressSet({scenarioId});
        if (localProgress) {  setProgress(localProgress)  }

        (async ()=>{
            const DatabaseProgress = await isDatabaseProgressSet({scenarioId, userId, loginKey});
            if (DatabaseProgress) {  setProgress(DatabaseProgress)  }
        })()

        return(
            <div id="scenario">
                <HowToPlay
                    userId={userId}
                    loginKey={loginKey}
                    scenario={scenario}
                    setScenario={setScenario}
                    setProgress={setProgress}
                />
            </div>
        )
    }
    const handleSoundfileDone = (audio) => {
        if ( audio && !audio.paused) { setSoundfile({currentSoundfile: null, currentAudio: null})}        
    }

    const handleSoundfile = (fileName) => {
        const {currentSoundfile, currentAudio} = soundfile;

        if (!currentSoundfile) {
            const audio = new Audio(`https://api.malstrom.art/murderMystery/soundFiles/${fileName}.mp3`);
            audio.play();
            setSoundfile({
                currentSoundfile:fileName,
                currentAudio: audio
            });

            audio.addEventListener("loadedmetadata", ()=>{
                const {duration} = audio;
                setTimeout(()=>{handleSoundfileDone(audio)},duration*1000);
            });
            return
        }

        // resume || pause
        if (fileName === currentSoundfile && !currentAudio.paused) { 
            setSoundfile({
                currentSoundfile,
                currentAudio
            });
            return currentAudio.pause();  }
        if (fileName === currentSoundfile && currentAudio.paused) { 
            const timeLeft = currentAudio.duration - currentAudio.currentTime;
            setSoundfile({
                currentSoundfile,
                currentAudio
            });
            currentAudio.play();
            setTimeout(()=>{handleSoundfileDone(currentAudio)},timeLeft*1000);
            return 
        }



        const audio = new Audio(`https://api.malstrom.art/murderMystery/soundFiles/${fileName}.mp3`);
        currentAudio.src = "";
        audio.play();
        setSoundfile({
            currentSoundfile:fileName,
            currentAudio: audio
        });
    }

    if (progress.ending) {
        return (
            <Results
                progress={progress}
                scenario={scenario}
                setScenario={setScenario}
                handleSoundfile={handleSoundfile}
                soundfile={soundfile}
                
            />
        )
    }

    const handleSetProgress = (fields) => {
        const body = {
            method: "PATCH",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                userId,
                loginKey,
                scenarioId,
                fields
            })
        }
        for (const field in fields) {
            progress[field] = fields[field];
        }

        console.log("test");
        localStorage.setItem("progress", JSON.stringify(progress));
        fetchProgress({body});

        setProgress({...progress});
    }

    const clearEvidence = () => {
        setEvidence({1:{}, 2:{}, 3:{}});
    }




    return (
        <div id="scenario">
            <div id="content">
                <Counter
                    progress={progress}
                    handleSetProgress={handleSetProgress}
                    scenario={scenario}
                    tabs={tabs}
                    setTabs={setTabs}
                    clearEvidence={clearEvidence}
                    handleSoundfile={handleSoundfile}
                    soundfile={soundfile}
                />
                <View 
                    view={view}
                    progress={progress}
                    setProgress={setProgress}
                    scenario={scenario}
                    setScenario={setScenario}
                    setTabs={setTabs}
                    tabs={tabs}
                    handleSoundfile={handleSoundfile}
                    soundfile={soundfile}
                />
                <Nav 
                    setView={setView} 
                    view={view}
                    setTabs={setTabs}
                    clearEvidence={clearEvidence}
                />
                {
                    tabs.length <= 0 ? null :
                    <OpenTab
                        tabData={tabs[tabs.length-1]}
                        tabs={tabs}
                        setTabs={setTabs}
                        progress={progress}
                        setProgress={setProgress} /* remove & use instead ->  handleSetProgress*/
                        scenario={scenario}
                        evidence={evidence}
                        setEvidence={setEvidence}
                        clearEvidence={clearEvidence}
                        chosenSuspect={chosenSuspect}
                        handleSetProgress={handleSetProgress}
                        setChosenSuspect={setChosenSuspect}
                        handleSoundfile={handleSoundfile}
                        soundfile={soundfile}
                        >
                    </OpenTab>
                }
            </div>
        </div>
    )
}

export default Scenario

function isLocalProgressSet({scenarioId}) {
    const localProgress = JSON.parse(localStorage.getItem("progress"));
    if (localProgress && scenarioId === localProgress.scenarioId) {
        return localProgress
    }

    localStorage.removeItem("progress");

    return false
}
async function isDatabaseProgressSet({scenarioId, userId, loginKey}) {
    const details = {
        userId,
        loginKey,
        scenarioId,
    }
    const {payload} = await fetchProgress(details);
    const DatabaseProgress = payload.progress.find(x => x.scenarioId === scenarioId);
    
    if (DatabaseProgress) {
        localStorage.setItem("progress", JSON.stringify(DatabaseProgress));
        return DatabaseProgress
    }

    return false
}

