import "./Map.css"

import React, { useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Circle, Popup} from 'react-leaflet';
import { Icon } from "leaflet";
import Loading from "../../../Loading/Loading";
import { filterCluesNotSolved, filterLocationsFound, getLocationClues } from "../../../../functions/caseFunctions.js";

function Map ({progress, scenario, tabs, setTabs}) {
    const [coordinates, setCoordinates] = useState(null);
    const [howAccurate, setAccuracy] = useState(0);
    const [usersPosition, setUsersPosition] = useState(null);
    const mapRef = useRef(null);

    const locationsFound = filterLocationsFound({progress, scenario});

    const handleSuccessfullGeoLocation = (e) => {
        const {latitude, longitude, accuracy} = e.coords;
        setAccuracy(Math.trunc(accuracy));
        setUsersPosition({lat: latitude, lng: longitude});
        if (accuracy > 35) {
            // if accuracy is too off get the latest location found
            const {coordinates} = scenario.locations[scenario.locations.length -1];
            setCoordinates(coordinates);
        } else {
            setCoordinates({
                lat: latitude,
                lng: longitude
            });
        }
    }
    const handleFailedGeoLocation = (e) => {
        const latestLocationFound = locationsFound[locationsFound.length -1];
        const {coordinates} = latestLocationFound;
        setCoordinates(coordinates);
    }



    // show on map where user is approximatly
    if (!coordinates) {
        navigator.geolocation.getCurrentPosition(handleSuccessfullGeoLocation, handleFailedGeoLocation);

        return (
            <div id="map">
                <div className="mapWrapper">
                    <Loading></Loading>
                </div>
            </div>)
    }


    const customIcon = new Icon ({
        iconUrl: require("./marker.png"),
        iconSize: [35,35]
    })


    return (
        <div id="map">
            <div className="mapWrapper colorAccent">
                <div className="loadingMapText">Loading map...</div>
                <MapContainer center={[coordinates.lat, coordinates.lng]} zoom={16} ref={mapRef} style={{height: "100vh", width: "100vw"}} zoomControl={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        subdomains= 'abcd'
                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        locationsFound.map(location =>{
                            const {id} = location;
                            const {clues} = progress;
                            const locationsClues = getLocationClues({scenario, locationId: id}).filter(x => !clues.includes(x.id));
                            {
                                return (
                                    <Circle center={location.coordinates} key={location.id} radius={location.radius} color="var(--main-color)">
                                        <Popup> <div>{location.name}</div> </Popup>
                                        {locationsClues.map(clue => (
                                                <Marker 
                                                icon={customIcon}
                                                key={clue.id} 
                                                position={clue.coordinates}
                                                eventHandlers={{
                                                    click: (e) => {
                                                        setTabs([...tabs, {type: "addClue", data:clue}])
                                                    },
                                                }}
                                                >
                                                </Marker>
                                            ))}    

                                    </Circle>
                                )
                            }


                        })
                    }
                    {
                        usersPosition === null ? (null) :
                        <Marker position={usersPosition}>
                            <Popup>Your location</Popup>
                        </Marker>
                    }
                </MapContainer>
            </div>
        </div>
    )
}

export default Map
