import { useState } from "react";
import "./Results.css"
import Stats from "./Stats/Stats";
import Conclusion from "./Conclusion/Conclusion";

function Results ({ progress, scenario, setScenario, handleSoundfile, soundfile}) {
    const {endingId} = progress.ending;
    const {conclusion, title, soundFileName} = scenario.endings.find(x => x.endingId === endingId);
    const [mode, setMode] = useState("stats");

    const handleSetMode = () => {
        mode === "stats" ?  setMode("conclusion") : setMode("stats");
    }

    return (
        <div id="Results">
            <div className="contentContainer">
                <h2 className="colorMain">{title}</h2>
                {mode === "stats" ? 
                    <Stats progress={progress} scenario={scenario}></Stats>
                    : 
                    <Conclusion conclusion={conclusion} soundFileName={soundFileName} handleSoundfile={handleSoundfile} soundfile={soundfile}></Conclusion>
                }
            </div>

            <div className="buttons">
                <button className="interactionButton" onClick={handleSetMode}>{mode === "stats" ? "read conclusion" : "see stats"}</button>
                <button className="interactionButton" onClick={()=>{setScenario(null)}}>Exit Game</button>
            </div>
        </div>
    )
}


export default Results