import { useState } from "react";
import "./Casefile.css"
import AudioButton from "../../general/AudioButton/AudioButton";

function Casefile ({setScenario ,scenario, handleSoundfile, soundfile}) {
    const {description, soundfileName} = scenario;
    const [buttonHidden, setButtonHidden] = useState(false);
    const handleScroll = (e) => {
        const scrollDistance = e.target.scrollTop;
        if (scrollDistance > 20 && !buttonHidden) {  setButtonHidden(true);  }
        if (scrollDistance < 20 && buttonHidden)  {  setButtonHidden(false); }
    }
    const handleTouchMove = (e) => {
        const scrollDistance = e.targetTouches[0].target.scrollTop;
        if (scrollDistance > 20 && !buttonHidden) {  setButtonHidden(true);  }
        if (scrollDistance < 20 && buttonHidden)  {  setButtonHidden(false); }
    }

    return (
        <div id="casefile">
            <div className="description"
            onTouchMove={handleTouchMove}
            onScroll={handleScroll}
            >{description}</div>
            <div className={buttonHidden ? "buttonWrapper hidden" : "buttonWrapper"}>
                <button  className="interactionButton"
                    onClick={()=>{setScenario(null)}} 
                    >Quit Game
                </button>

                <AudioButton
                    fileName={soundfileName}
                    soundfile={soundfile}
                    handleSoundfile={handleSoundfile}
                />
            </div>
        </div>
    )
}

export default Casefile