import './MainMenu.css'

function MainMenu ({user, setUser, playScenario, setScenario, openLeaderBoard, setToken}) {
    const {username} = user;

    const handleLogout = () => {
        localStorage.clear();
        setUser(null);
        setScenario(null);
        setToken(null);
    }
    return (
        <div id="mainMenu">
            <h2 className="titleText colorMain"></h2>

            <ul>
                <button className='interactionButton' onClick={()=> {playScenario()}}>play</button>
                <button className='interactionButton' onClick={openLeaderBoard}>leaderboard</button>
                <button className='interactionButton'  onClick={handleLogout}>logout</button>
            </ul>

            <div className="user colorMain">{username}</div>
        </div>
    )
}

export default MainMenu