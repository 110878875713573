import { useState } from "react";
import "./ChooseEvidenceTab.css"
import { filterCluesFound } from "../../../../functions/caseFunctions";
import Li from "../../general/Li/Li";

function ChooseEvidenceTab ({scenario, setProgress, progress, evidence, setEvidence, tabs, setTabs, removeCurrentTab}) {
    const cluesFound = filterCluesFound({progress, scenario});
    return (
        <div id="chooseEvidenceTab" className="tabContent">
            <div className="title">Choose Evidence</div>
            <ul className="listOfClues">
                {cluesFound.map(clue => 
                    <Li
                        key={clue.id}
                        data={clue}
                        LiEvent={(e) => {
                            setEvidence(chooseEvidence(evidence, clue));
                            removeCurrentTab(e);
                        }}
                        interactionButtonEvent={() => {
                            setTabs([...tabs, {type: "clue", data:clue}])
                        }}
                        >
                    </Li>
                )}
            </ul>

        </div>
    )
}

function chooseEvidence(evidence, clue) {
    // if so replace
    const evidenceCopy = Object.assign({}, evidence);
    const clueId = clue.id;

    // check if chosen clue id is already in use
    for (const key in evidenceCopy) {
        const {active, id} = evidenceCopy[key];
        if (id === clueId) {
            if (active) {
                evidenceCopy[key] = {active}
            } else {
                evidenceCopy[key] = {}
            }
        }
    }

    for (const key in evidenceCopy) {
        const {active, id} = evidenceCopy[key];
        if (active) {
            evidenceCopy[key] = clue;
        }
    }

    return evidenceCopy;

    
}

export default ChooseEvidenceTab