import Li from "../../general/Li/Li";
import "./Suspects.css"

function Suspects ({progress, scenario, tabs, setTabs}) {
    const {suspects} = scenario;
    return (
        <div id="suspects">
            <ul className="listOfSuspects">
                {suspects.map(suspect => 
                    <Li
                        key={suspect.id}
                        data={suspect}
                        LiEvent={() => {
                            setTabs([...tabs, {type: "suspect", data:suspect}])
                        }}
                        >
                    </Li>
                )}
            </ul>

            <div className="buttonWrapper">
                <button className="accusationButton interactionButton"
                    onClick={()=>{setTabs([...tabs, {type: "accusation", data: null}])}}
                >Make Accusation
                </button>
            </div>
        </div>
    )
}

export default Suspects