import { filterCluesFound } from "../../../../functions/caseFunctions";
import Li from "../../general/Li/Li";
import "./Clues.css"

function Clues ({progress, scenario, tabs, setTabs}) {
    const cluesFound = filterCluesFound({progress, scenario});
    return (
        <div id="clues">
            <ul className="listOfClues">
                {cluesFound.map(clue => 
                    <Li
                        key={clue.id}
                        data={clue}
                        LiEvent={() => {
                            setTabs([...tabs, {type: "clue", data:clue}])
                        }}
                        >
                    </Li>
                )}
            </ul>
        </div>
    )
}

export default Clues