import "./Nav.css"
function Nav ({setView, view, setTabs, clearEvidence}) {
    const views = [
        "Casefile",
        "Suspects",
        "Map",
        "Clues"
    ];

    const changeView = (v) => {
        // only change view if view clicked isn't the current one otherwise it just clears tabs
        setTabs([]);
        clearEvidence();

        if (v !== view) {
            setView(v);
        }
    }
    return (
        <div id="nav">
            {views.map(v => <button
                                onClick={()=>{changeView(v)}}
                                className={view === v ? `${v} selected`: v}                                
                                key={v}>
                                <div className="bg"></div>
                            </button>
            )}
        </div>
    )
}

export default Nav