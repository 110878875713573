import "./AudioButton.css"

function AudioButton ({fileName, handleSoundfile, soundfile}) {
    const {currentSoundfile, currentAudio} = soundfile;
    
    // inget spelas
    // denna ljudfilen spelas dvs paus symbol
    // en annan ljudfil spelas
    
    return (
        <button
        className="audioButton"
        onClick={()=>{handleSoundfile(fileName)}}>
            <div className={currentSoundfile === fileName ? "icon playing" : "icon"}></div>
        </button>
    )
}

export default AudioButton