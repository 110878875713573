import "./SuspectsTab.css"
import AudioButton from "../../general/AudioButton/AudioButton";
function SuspectsTab ({data, handleSoundfile, soundfile}) {
    const {image, name, age, alibi, motive, description, soundfileName } = data;
    console.log(soundfileName);
    return (
        <div className="suspectsTab tabContent">
            <div className="topContainer">
                <div className="topInfoWrapper">
                    <img className="image" src={`./media/characters&items/${image}`} alt={`${name}`} />
                    {!soundfileName ? null:(
                        <AudioButton
                        fileName={soundfileName}
                        handleSoundfile={handleSoundfile}
                        soundfile={soundfile}
                    />)}
                </div>
                <h3 className="name">{name}</h3>

            </div>

            <div className="descriptionContainer">
                <p>age: {age}</p>
                <p>Reason for investigation: {motive}</p>
                <p>alibi: {alibi}</p>
                <p className="description">description: {description}</p>
            </div>
        </div>
    )
}

export default SuspectsTab